import React, { Component } from 'react';
import koco from "../images/koco.png"
import {Link,withRouter} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import jwtDecode from "jwt-decode";
import Axios from "axios";
import ApiUrl from "../AppUrl/ApiUrl";


class TopMenu extends Component {
    constructor() {
        super();
        this.state={
            sideBar:"sideBarClose",overLay:"sideLayoutClose",
            profilemenu:"profilemenuClose",profileLayout:"profileLayoutClose",
            token:"",email:"",uid:"",email2:"",verify:""
        }
    }
    componentDidMount() {
        var verify = localStorage.getItem("verify")
        if(verify){
            this.setState({verify:verify})
        }
        var token = localStorage.getItem("authtoken")
        if(token){
            var decoded = jwtDecode(token)
            //console.log(decoded)
            this.setState({token:token,uid:decoded.uid,email:decoded.email})
            var email2 = decoded.email
            var mail = email2.split("@")
            //console.log(email2.split("@"))
            this.setState({email2:mail[0].substring(0,4)+"*****@"+mail[1]})
            var formd = new FormData()
            formd.append("token",token)
            Axios.post(ApiUrl.baseurl+"auth/profile",formd)
                .then(res=>{
                    this.setState({verify:res.data.verify,step:"3"})
                    localStorage.setItem("verify",res.data.verify)
                    if(res.data["profile_status"]=="0"){
                        console.log(res.data["profile_status"])
                        localStorage.removeItem("authtoken")
                    }
                })
                .catch(err=>{
                    //
                })
        }
    }
    menuClick=()=>{
        if(this.state.sideBar=="sideBarClose"){
            this.setState({sideBar:"sideBarOpen",overLay:"sideLayoutOpen"})
        }else{
            this.setState({sideBar:"sideBarClose",overLay:"sideLayoutClose"})
        }
    }
    profileClick=()=>{
        if(this.state.profilemenu=="profilemenuClose"){
            this.setState({profilemenu:"profilemenuOpen",profileLayout:"profileLayoutOpen"})
        }else{
            this.setState({profilemenu:"profilemenuClose",profileLayout:"profileLayoutClose"})
        }
    }
    errorMsg=(val)=>{
        toast.error(val, {
            theme: "colored",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    successMsg=(val)=>{
        toast.info(val,  {
            theme: "colored",
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    logOut=()=>{
        localStorage.removeItem("authtoken")
        this.successMsg("Successfully Logout")
        setTimeout(()=>{
            this.props.history.push("/login")
        },500)
    }
    comingSoon=()=>{
        this.successMsg("Coming soon...")
    }
    render() {
        var val = this.state
        return (
            <>
                <div onClick={this.profileClick} className={this.state.profileLayout}></div>
                <div className={this.state.profilemenu}>
                    <div className="headp">
                        <h3>{val.email2}</h3>
                        <p>UID : {val.uid}
                        </p>

                    </div>
                    <ul>
                        <li><Link to="/wallet">Wallet</Link></li>
                        <li><Link to="/wallet/deposit">Deposit</Link></li>
                        <li><Link to="/wallet/withdrew">Withdrew</Link></li>
                        <li><Link to="/mining">Mining</Link></li>
                        <li><Link to="/my/referral">Referral</Link></li>
                        <li><Link to="/wallet/history">Payments</Link></li>
                        <li><Link to="/change-password">Change Password</Link></li>
                        <li style={{color:val.verify=="Approved" || val.verify=="Inreview"?"green":"black"}}>
                            {val.verify=="Approved" || val.verify=="Inreview"?
                            "Verification " + val.verify
                                :
                                <Link
                                style={{color:val.verify=="Approved" || val.verify=="Inreview"?"green":"black"}} to="/kyc">
                                Verification {val.verify==""?"Verify Now":val.verify}
                                </Link>
                            }

                        </li>
                        <li onClick={this.logOut} className="text-center"><Link to="#">Logout</Link></li>
                    </ul>
                </div>
                <div className="TopMenu">
                    <title>{this.props.title}</title>
                    <div className="logo">
                        <Link to="/">
                            <img src={koco}/>
                        </Link>
                    </div>
                    <div className="rightMenu">
                        <ul className="middlemenu">
                            <li onClick={this.comingSoon}><Link to="#">Trade</Link></li>
                            <li onClick={this.comingSoon}><Link to="#">Buy Crypto </Link></li>
                            <li onClick={this.comingSoon}><Link to="#">Markets</Link></li>
                            <li onClick={this.comingSoon}><Link to="#">Futures</Link></li>
                            <li><Link to="/airdrop">Airdrop</Link></li>
                            <li><Link to="/mining">Mining </Link></li>
                            <li><Link target="_blank" to="/roadmap.pdf">Roadmap </Link></li>
                            <li onClick={this.comingSoon}>More</li>
                        </ul>
                        <ul className="lastmenu">
                            <li onClick={this.menuClick}><i className="far fa-bars"></i></li>
                            {
                                val.token?
                                    <li onClick={this.profileClick} className="usericon"><i className="far fa-user"></i></li>
                                    :""
                            }
                            <li>
                                <a target="_blank" href="/app-release.apk">
                                   <i className="far fa-download"></i>
                               </a>
                            </li>
                            {
                                val.token==""?
                                    <>
                                        <li><Link className="btnsignin" to="/register">Sign Up</Link></li>
                                        <li><Link className="btnsignin" to="/login">Log In</Link></li>
                                    </>
                                    :""
                            }
                            <li>
                                <div className="topsearch" style={{
                                    borderRadius:"20px",paddingLeft:"10px",
                                    marginRight:"10px"
                                }}>
                                    <i style={{fontSize:"15px",paddingRight:"5px"}} className="fas fa-search"></i>
                                    <input
                                        style={{border:"none",
                                            outline:"none",width:"150px",
                                            background:"transparent",
                                            borderRadius:"25px"}}
                                        placeholder="search here"/>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="menuExtra"></div>
                <ToastContainer
                    theme="colored"
                    position="top-right"
                    autoClose={1000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className={this.state.sideBar}>
                    <div className="row m-0">
                        <div className="col-md-12">
                            <i onClick={this.menuClick} className="fas fa-times"></i>
                        </div>
                        {
                            val.token?"":
                                <>
                                    <div className="col-md-6 col-12">
                                        <Link to="/register" type="button" className="signupbtn">Sign Up</Link>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <Link to="/login" type="button" className="loginbtn">Login</Link>
                                    </div>
                                </>
                        }
                        <div className="col-md-12">
                            <ul className="">
                                <li onClick={this.comingSoon}><Link to="#">Trade <i className="fas fa-sort-down float-end"></i></Link></li>
                                <li onClick={this.comingSoon}><Link to="#">Buy Crypto <i className="fas fa-sort-down float-end"></i></Link></li>
                                <li onClick={this.comingSoon}><Link to="#">Markets <i className="fas fa-sort-down float-end"></i></Link></li>
                                <li onClick={this.comingSoon}><Link to="#">Futures <i className="fas fa-sort-down float-end"></i></Link></li>
                                <li ><Link to="/airdrop">Airdrop <i className="fas fa-sort-down float-end"></i></Link></li>
                                <li><Link to="/mining">Mining </Link></li>
                                <li><Link target="_blank" to="/roadmap.pdf">Roadmap </Link></li>
                                <li onClick={this.comingSoon}>More</li>
                            </ul>
                            <p style={{marginBottom:"0",marginTop:"50px"}}>
                                Download Koco <a target="_blank" href="/app-release.apk"><i className="fas fa-download float-end" style={{cursor:"pointer"}}></i></a>
                            </p>
                            <p style={{marginBottom:"0"}}>
                                English/USD <i className="fas fa-language float-end" style={{cursor:"pointer"}}></i>
                            </p>
                            <p style={{marginBottom:"0"}}>
                                Currency <i className="fas fa-globe float-end" style={{cursor:"pointer"}}></i>
                            </p>
                        </div>
                    </div>
                </div>
                <div onClick={this.menuClick} className={this.state.overLay}></div>
            </>
        );
    }
}

export default withRouter(TopMenu);
