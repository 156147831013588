import React, {Component} from 'react';
import Wallet from "../../page/Wallet";

class Funding extends Component {
    render() {
        return (
            <Wallet>
                <h3 style={{textAlign:"center",marginTop:"180px"}}>Coming soon ....</h3>
            </Wallet>
        );
    }
}

export default Funding;