import React, {Component} from 'react';
import TopMenu from "../components/TopMenu";
import Footer from "../components/Footer";

class About extends Component {
    render() {
        return (
            <>
                <TopMenu title="About KocoCoin"/>
                <div className="terms">
                    <h1>About KocoCoin</h1>
                    <br/>
                    <p>
                        About KocoCoin Founded in 2024, KocoCoin is known as the exchange of high performance and mega transaction matching technology. The team at KocoCoin are some of the first movers and pioneers of
                        financial and blockchain technology. Currently, KocoCoin caters to than 170 countries and regions around the world and we have just started. This
                        magnificent achievement is a significant milestone, and KocoCoin aims to become the go-to platform for new traders and experienced investors as they move forward in their financial
                        Journey.
                        <br/><br/>
                        KocoCoin has presence over and across major continents and jurisdictions around the globe and is regulated . either irectly or through affiliates, by some of the world's most renowned
                        jurisdictions. KocoCoin also offers localized language support for investors from different countries. making it easier for them to trade. The exchange at KocoCoin is a high-performance
                        trading engine which has been developed by experts from the banking industry and is capable of completing 1.4 million transactions per second, which results in groundbreaking efficiency
                        and enhanced performance. User security is of top priority at KocoCoin because of which our servers are hosted independently across multiple countries ensuring optimal data integrity and
                        security.
                    </p>
                    <br/><br/><br/><br/><br/><br/>
                </div>

                <Footer/>
            </>
        );
    }
}

export default About;
