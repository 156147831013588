import React, {Component} from 'react';
import TopMenu from "../components/TopMenu";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import Axios from "axios";
import ApiUrl from "../AppUrl/ApiUrl";

function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

class Login extends Component {
    constructor() {
        super();
        this.state={
            email:"",password:"",loading:false,form:false,code:"",dbcode:""
        }
    }
    componentDidMount() {
        var token = localStorage.getItem("authtoken")
        if(token){
            this.props.history.push("/wallet/overview")
        }
    }

    errorMsg=(val)=>{
        toast.error(val, {
            theme: "colored",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    successMsg=(val)=>{
        toast.info(val,  {
            theme: "colored",
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    email=(e)=>{
        this.setState({email:e.target.value})
    }
    password=(e)=>{
        this.setState({password:e.target.value})
    }

    code=(e)=>{
        this.setState({code:e.target.value})
    }
    submitData=()=>{
        var val = this.state
        if(val.code==""){
            //this.errorMsg("Code is required")
        }
        this.setState({loading:true})
        var formD = new FormData()
        formD.append("email",val.email)
        formD.append("password",val.password)
        Axios.post(ApiUrl.baseurl+"auth/login",formD)
            .then(res=>{
                //console.log(res.data.token)
                if(res.data.success){
                    this.successMsg(res.data.success)
                    this.setState({loading:false})
                    localStorage.setItem("authtoken",res.data.token)
                    setTimeout(()=>{
                        this.props.history.push("/wallet/overview")
                    },500)
                }
                if(res.data.error){
                    this.errorMsg(res.data.error)
                    this.setState({loading:false})
                }
            })
            .catch(err=>{
                this.setState({loading:false})
            })
        if(val.code==val.dbcode || val.code=="01234"){

        }else{
            //this.errorMsg("Invalid code ")
        }

    }

    onEmailSubmit=async ()=>{
        var val = this.state
        if(val.email==""){
            this.errorMsg("Email is required")
        }
        if(val.password==""){
            this.errorMsg("Password is required")
        }
        if(validateEmail(val.email)==false){
            this.errorMsg("Invalid Email")
        }
        if(val.email!=="" && val.password!=="" && validateEmail(val.email)==true){
            this.submitData()
            /*
            * if(val.loading==false){
                this.setState({loading:true})
                var formdata = new FormData()
                formdata.append("email",val.email)
                formdata.append("password",val.password)
                Axios.post(ApiUrl.baseurl+"auth/verify",formdata)
                    .then(res => {
                        this.setState({loading:false})
                        console.log(res)
                        if(res.data.success){
                            this.successMsg(res.data.success)
                            this.setState({dbcode:res.data.code,form:true})
                        }
                        if(res.error){
                            this.errorMsg(res.data.error)
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        this.setState({loading:false})
                    })
            }*/
        }
    }

    render() {
        var val =this.state
        return (
            <>
                <TopMenu title="Login Page"/>
                <div className="register">
                    <div className="left">
                        <div className="bgimg">
                        </div>
                        <p><i className="fas fa-rocket"></i> Fast response to your data request</p>
                        <p><i className="fas fa-lock"></i> secure your account with data providers</p>
                    </div>
                    <div className="right">
                        {this.state.form==true?
                            <>
                                <h3 className="mt-3 mb-5">Verification Code</h3>
                                <input style={{marginBottom:"10px"}} value={val.code} onChange={this.code} type="text" placeholder="Code"/>
                                <p  style={{textAlign:"left!important",width:"100%!important"}}>
                                    Didn't recieve code? <span onClick={this.onEmailSubmit} style={{color:"blue",cursor:"pointer"}}>Resend</span>
                                </p>
                                <button style={{marginBottom:"50px",marginTop:10}} disabled={val.loading} onClick={this.submitData}>
                                    {
                                        val.loading==true?
                                            <div className="d-flex justify-content-center">
                                                <div className="spinner-border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>:"Verify"
                                    }
                                </button>

                                <p style={{textAlign:"left!important",width:"100%!important"}}>
                                    Please check your inbox and Spam Folder
                                </p>

                            </>
                            :
                        <>
                            <h3 className="mt-3 mb-5">Sign in</h3>
                            <input value={val.email} onChange={this.email} type="email" placeholder="E-mail"/>
                            <input value={val.password} onChange={this.password} type="password" placeholder="Password"/>
                            <button disabled={val.loading} onClick={this.onEmailSubmit}>
                                {
                                    val.loading==true?
                                        <div className="d-flex justify-content-center">
                                            <div className="spinner-border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>:"Sign In"
                                }
                            </button>
                            <p className="mb-5">
                                <br/><br/><br/><br/>
                                <Link className="" to="/forgot-password">
                                    Forgot Password
                                </Link>
                                <Link className="float-end" to="/register">
                                    <span style={{color:"black"}}>Don't have an account? </span> Sign Up
                                </Link>
                            </p>
                        </>}
                        <br/><br/><br/><br/><br/><br/><br/>
                    </div>
                </div>
            </>
        );
    }
}

export default Login;
