import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Axios from "axios";
import ApiUrl from "../../AppUrl/ApiUrl";
import Wallet from "../../page/Wallet";

class OverView extends Component {
    constructor() {
        super();
        this.state={
            bal:"show",btc:"0",wallet:[]
        }
    }
    componentDidMount() {
        var token = localStorage.getItem("authtoken")
        if(token){
            this.setState({token:token})
            setTimeout(()=>{
                this.getWallet()
            },500)
        }else {
            this.props.history.push("/login")
        }
        var walletdata = JSON.parse(localStorage.getItem("wallet"))
        if(walletdata){
            this.setState({wallet:walletdata,loading:false})
        }
        var btc = localStorage.getItem("btc")
        if(btc){
            this.setState({btc:btc})
        }
        Axios.get("https://api.coinpaprika.com/v1/tickers/btc-bitcoin")
            .then(res=>{
                //console.log(res.data.quotes["USD"]["price"])
                var bal =res.data.quotes["USD"]["price"]
                localStorage.setItem("btc",bal)
                this.setState({btc:bal})
            })
            .catch(err=>{
                //console.log(err)
            })
    }
    getWallet=()=>{
        var formd = new FormData()
        formd.append("token",this.state.token)
        Axios.post(ApiUrl.baseurl+"auth/mywallet",formd)
            .then(res=>{
                //console.log(res.data.data)
                if (res.data.success){
                    if(res.data.data.length>0){
                        this.setState({wallet:res.data.data,loading:false})
                        localStorage.setItem("wallet",JSON.stringify(res.data.data))
                    }
                }
            })
            .catch(err=>{
                this.getWallet()
            })
    }

    bal=(val)=>{
        this.setState({bal:val})
    }
    render() {
        var usd=0
        var deskwallet = this.state.wallet.map(res=>{
            var value =(parseFloat(res.balance)*parseFloat(res.price))
            usd+=value
            return(
                <>

                </>
            )
        })
        var btcvalue = (usd/parseFloat(this.state.btc)).toFixed(5)
        return (
            <Wallet>
                <div className="spot">
                    <div className="head">
                        <div className="row">
                            <div className="col-md-6">
                                <h3>Spot Balance <Link className="historybtn" to="/wallet/history"><i className="far fa-book float-end"></i></Link></h3>
                                <p>Estimated Balance
                                    {this.state.bal=="show"?
                                        <i onClick={this.bal.bind(this,"hide")} className="fas fa-eye"></i>:
                                        <i onClick={this.bal.bind(this,"show")} className="fas fa-eye-slash"></i>
                                    }
                                </p>
                                <h2>{this.state.bal=="hide"?"*****":btcvalue} BTC ≈{this.state.bal=="hide"?"*****":usd.toFixed(2)} USD</h2>
                                <p></p>
                            </div>
                            <div className="col-md-6 ">
                                <Link className="float-end" to="#">Transfer</Link>
                                <Link className="float-end" to="/wallet/withdrew">Withdrew</Link>
                                <Link className="float-end" to="/wallet/deposit">Deposit</Link>
                            </div>
                        </div>
                    </div>

                    <div className="porfolio">
                        <h3>Portfolio</h3>
                        <ul>
                            <li>
                                <Link to="/wallet/overview">
                                    <i className="far fa-list" style={{paddingRight:"8px"}}></i> Overview
                                    <span className="float-end">{this.state.bal=="hide"?"*****":btcvalue} BTC <i className="far fa-chevron-right"
                                                                                  style={{paddingLeft:"8px"}}></i></span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/wallet/spot">
                                    <i className="far fa-hockey-puck" style={{paddingRight:"8px"}}></i> Spot
                                    <span className="float-end">{this.state.bal=="hide"?"*****":btcvalue} BTC <i className="far fa-chevron-right"
                                                                                  style={{paddingLeft:"8px"}}></i></span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/wallet/futures">
                                    <i className="far fa-dice-d6" style={{paddingRight:"8px"}}></i> Futures
                                    <span className="float-end">{this.state.bal=="hide"?"*****":0} BTC <i className="far fa-chevron-right"
                                                                         style={{paddingLeft:"8px"}}></i></span>
                                </Link>
                            </li>
                            <li>
                                <Link  to="/wallet/funding">
                                    <i className="far fa-radiation-alt" style={{paddingRight:"8px"}}></i> Funding
                                    <span className="float-end">{this.state.bal=="hide"?"*****":0} BTC <i className="far fa-chevron-right"
                                                                         style={{paddingLeft:"8px"}}></i></span>
                                </Link>
                            </li>
                        </ul>
                    </div>

                </div>
            </Wallet>
        );
    }
}

export default OverView;