import React, {Component} from 'react';
import TopMenu from "../components/TopMenu";
import Footer from "../components/Footer";
import {Link} from "react-router-dom";
import jwtDecode from "jwt-decode";
import Axios from "axios";
import ApiUrl from "../AppUrl/ApiUrl";

class Wallet extends Component {
    constructor() {
        super();
        this.state={
            path:"overview",token:""
        }
        this.interval=null
    }
    componentDidMount() {
        var token = localStorage.getItem("authtoken")
        if(token){
            this.setState({token:token})
            setTimeout(()=>{
                this.getDeposit()
            },300)
        }else {
            this.props.history.push("/login")
        }
        this.getPath()
        this.interval= setInterval(()=>{
            this.getDeposit()
        },30000)
    }
    getDeposit=()=>{
        var formd = new FormData()
        formd.append("token",this.state.token)
        Axios.post(ApiUrl.baseurl+"auth/deposit",formd)
            .then(res=>{
                //console.log(res)
            })
            .catch(err=>{
                //console.log(err)
            })
    }

    getPath=()=>{
        var path =window.location.pathname
        //console.log(path)
        if(path=="/wallet/history"){
            this.setState({path:"history"})
        }
        if(path=="/wallet/overview"){
            this.setState({path:"overview"})
        }
        if(path=="/wallet/spot"){
            this.setState({path:"spot"})
        }
        if(path=="/wallet/futures"){
            this.setState({path:"futures"})
        }
        if(path=="/wallet/funding"){
            this.setState({path:"funding"})
        }
    }

    onPath=(val)=>{
        this.setState({path:val})
    }
    componentWillUnmount(){
        //console.log(this.interval)
        try{
            clearInterval(this.interval)
            this.interval=null
        }catch(err){
            console.log(err)
        }
    }
    render() {
        return (
            <>
                <TopMenu title={"Wallet "+this.state.path}/>
                <div className="wallet">
                   <div className="leftmenu">
                       <ul>
                           <li onClick={this.onPath.bind(this,"overview")}>
                               <Link className={this.state.path=="overview"?"active":""} to="/wallet/overview">
                                   <i className="far fa-list"></i> Overview</Link>
                           </li>
                           <li onClick={this.onPath.bind(this,"spot")}>
                               <Link  className={this.state.path=="spot"?"active":""} to="/wallet/spot">
                                   <i className="far fa-hockey-puck"></i> Spot</Link>
                           </li>
                           <li onClick={this.onPath.bind(this,"futures")}>
                               <Link className={this.state.path=="futures"?"active":""} to="/wallet/futures">
                                   <i className="far fa-dice-d6"></i> Futures</Link>
                           </li>
                           <li onClick={this.onPath.bind(this,"fiat")}>
                               <Link className={this.state.path=="funding"?"active":""} to="/wallet/funding">
                                   <i className="far fa-radiation-alt"></i> Funding</Link>
                           </li>
                           <li onClick={this.onPath.bind(this,"history")}>
                               <Link className={this.state.path=="history"?"active":""} to="/wallet/history">
                                   <i className="far fa-book"></i> Transaction History</Link>
                           </li>
                       </ul>
                   </div>
                    <div className="rightmenu">
                        <ul>
                            <li onClick={this.onPath.bind(this,"overview")}>
                                <Link className={this.state.path=="overview"?"active":""} to="/wallet/overview">
                                    Overview</Link>
                            </li>
                            <li style={{textAlign:"center"}} onClick={this.onPath.bind(this,"spot")}>
                                <Link style={{textAlign:"center"}} className={this.state.path=="spot"?"active":""} to="/wallet/spot">
                                    Spot</Link>
                            </li>
                            <li style={{textAlign:"right"}} onClick={this.onPath.bind(this,"futures")}>
                                <Link style={{textAlign:"center"}} className={this.state.path=="futures"?"active":""} to="/wallet/futures">
                                    Futures</Link>
                            </li>
                            <li onClick={this.onPath.bind(this,"funding")}>
                                <Link style={{textAlign:"right",paddingRight:"20px"}} className={this.state.path=="funding"?"active":""} to="/wallet/funding">
                                    Funding</Link>
                            </li>
                        </ul>
                        <div className="showdata">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            
            </>
        );
    }
}

export default Wallet;