import React, {Component} from 'react';
import TopMenu from "../components/TopMenu";
import Footer from "../components/Footer";
import {Link} from "react-router-dom";
import Axios from "axios";
import ApiUrl from "../AppUrl/ApiUrl";
import ReactHtmlParser from "react-html-parser";
import loadinggif from"../images/loading.gif"
import moment from "moment";

class Blog extends Component {
    constructor() {
        super();
        this.state={
            blog:[],loading:true
        }
    }
    componentDidMount() {
        var blog = JSON.parse(localStorage.getItem("blog"))
        if(blog){
            this.setState({blog:blog,loading:false})
        }
        this.getBlog()
    }
    getBlog=()=>{
        Axios.get(ApiUrl.baseurl+"all-blog")
            .then(res=>{
                if(res.data.error){
                    console.log("err")
                }else{
                    if(res.data.length>0){
                        localStorage.setItem("blog",JSON.stringify(res.data))
                        this.setState({blog:res.data,loading:false})
                    }
                }
            })
            .catch(err=>{
                //console.log(err)
                this.getBlog()
            })
    }

    render() {
        var bloglist = this.state.blog.map(res=>{
            return(
                <div className="col-md-4 mb-3">
                    <Link to={"/news-blog/"+res.id}>
                        <div className="card" style={{width:"100%",border:"none"}}>
                            <img className="card-img-top blogsmimg"
                                 src={ApiUrl.photoUrl+res.img} alt="Card image cap"/>
                            <div className="card-body p-0 mt-3">
                                <h5 className="card-title">{res.title.substring(0,50)}</h5>
                                <p className="card-text reactquilldes">{ReactHtmlParser(res.des.substring(0,200)+" .....")}</p>
                                <p>{moment(res.created_at).format(("YYYY-MM-DD HH:mm:ss"))}</p>
                            </div>
                        </div>
                    </Link>
                </div>
            )
        })
        return (
            <>
                <TopMenu title="News and Blog" />
                <div className="blog">
                    <h3>KocoCoin Blog and News</h3>
                    <br/>
                    {
                        this.state.loading==true?
                            <div style={{width:"100%",height:"100%",background:"white"}}>
                                <img style={{height:"250px",width:"100%"}} src={loadinggif}/>
                                <img style={{height:"250px",width:"100%",paddingBottom:"50px"}} src={loadinggif}/>
                            </div>
                            :
                            <div className="row ">
                                {bloglist}
                            </div>
                    }
                </div>

                <Footer/>
            </>
        );
    }
}

export default Blog;
