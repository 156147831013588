import React, {Component} from 'react';
import TopMenu from "../components/TopMenu";
import Footer from "../components/Footer";
import {CountryList} from "../components/coutrylist"
import Axios from "axios";
import ApiUrl from "../AppUrl/ApiUrl";
import {toast} from "react-toastify";
import loadinggif from"../images/loading.gif"

class Kyc extends Component {

    constructor() {
        super();
        this.state={
            dtype:"",token:"",country:"",twitter:"",telegram:"",
            verify:"",nidno:"",frontp:"",frontp1:"",backp:"",
            backp1:"",checkbox:false,loading:false,step:"1",loading1:true
        }
    }

    componentDidMount() {
        var verify = localStorage.getItem("verify")
        if(verify){
            this.setState({verify:verify,loading1:false})
        }
        const query = new URLSearchParams(this.props.location.search);
        var token1 = query.get('token')
        if(token1){
            this.setState({token:token1})
            console.log(token1)
            localStorage.setItem("authtoken",token1)
            setTimeout(()=>{
                this.getProfile()
            },50)
        }
        var token = localStorage.getItem("authtoken")
        if(token!=="" || token1!==""){
            if(token){
                this.setState({token:token})
            }
            setTimeout(()=>{
                this.getProfile()
            },50)
        }else {
            this.props.history.push("/login")
        }
    }

    getProfile=()=>{
        var formd = new FormData()
        formd.append("token",this.state.token)
        Axios.post(ApiUrl.baseurl+"auth/profile",formd)
            .then(res=>{
                console.log(res.data.verify)
                if(res.data.verify=="Inreview" || res.data.verify=="Approved"){
                    this.setState({verify:res.data.verify,step:"3"})
                    localStorage.setItem("verify",res.data.verify)
                }else{
                    this.setState({verify:res.data.verify})
                    localStorage.setItem("verify",res.data.verify)
                }

                if(res.data.country!=="NA"){
                    this.setState({country:res.data.country})
                }
                if(res.data.dtype!=="NA"){
                    this.setState({dtype:res.data.dtype})
                }
                if(res.data.nidno!=="NA"){
                    this.setState({nidno:res.data.nidno})
                }
                if(res.data.twitter!=="NA"){
                    this.setState({twitter:res.data.twitter})
                }
                if(res.data.telegram!=="NA"){
                    this.setState({telegram:res.data.telegram})
                }
                this.setState({loading1:false})
            })
            .catch(err=>{
                //
            })
    }

    dtype=(e)=>{
        this.setState({dtype:e.target.value})
    }
    country=(e)=>{
        this.setState({country:e.target.value})
    }
    twitter=(e)=>{
        this.setState({twitter:e.target.value})
    }
    telegram=(e)=>{
        this.setState({telegram:e.target.value})
    }
    nidno=(e)=>{
        this.setState({nidno:e.target.value})
    }
    checkbox=()=>{
        if(this.state.checkbox==false){
            this.setState({checkbox:true})
        }else {
            this.setState({checkbox: false})
        }
    }
    frontp=(e)=>{
        this.setState({frontp:e.target.files[0]})
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function (e) {
            this.setState({
                frontp1: [reader.result]
            })
        }.bind(this);
    }
    removefrontp=()=>{
        this.setState({frontp:"",frontp1:""})
        console.log("Front Image Remove")
    }
    backp=(e)=>{
        this.setState({backp:e.target.files[0]})
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function (e) {
            this.setState({
                backp1: [reader.result]
            })
        }.bind(this);
    }

    removebackp=()=>{
        this.setState({backp:"",backp1:""})
    }

    nextc=()=>{
        var val = this.state
        if(val.country!=="" && val.dtype!=="" && val.nidno!=="" && val.frontp!=="" && val.backp!=="" && val.checkbox==true){
            this.setState({step:"2"})
        }else{
            this.errorMsg("All Requirements is required")
        }
    }
    errorMsg=(val)=>{
        toast.error(val, {
            theme: "colored",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    successMsg=(val)=>{
        toast.info(val,  {
            theme: "colored",
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    submitD=()=>{
        var val = this.state
        if(val.country!=="" && val.dtype!=="" && val.nidno!=="" && val.frontp!=="" && val.backp!=="" && val.checkbox==true && val.twitter!=="" && val.telegram!==""){
            this.setState({loading:true})
            var formD = new FormData()
            formD.append("token",val.token)
            formD.append("dtype",val.dtype)
            formD.append("nidno",val.nidno)
            formD.append("country",val.country)
            formD.append("twitter",val.twitter)
            formD.append("telegram",val.telegram)
            formD.append("frontp",val.frontp)
            formD.append("backp",val.backp)
            Axios.post(ApiUrl.baseurl+"auth/kycup",formD)
                .then(res=>{
                    console.log(res.data)
                    if(res.data.success){
                        this.successMsg(res.data.success)
                        this.setState({verify:"Inreview",step:"3",loading:false})
                    }
                    if(res.data.error){
                        this.errorMsg(res.data.error)
                        this.setState({loading:false})
                    }
                })
                .catch(err=>{
                    this.setState({loading:false})
                })
        }else{
            this.errorMsg("All Requirements is required")
        }
    }

    render() {
        var val=this.state
        //console.log(val.frontp1)
        var countrylist = CountryList.map(res=>{
            return(
                <option selected={val.country==res.name} value={res.name}>{res.name}</option>
            )
        })

        return (
            <>
                <TopMenu title={"Kyc Verification"}/>
                {
                    val.loading1==true?
                        <div style={{width:"100%",height:"100%",background:"white"}}>
                            <img style={{height:"250px",width:"100%",paddingTop:"50px"}} src={loadinggif}/>
                            <img style={{height:"250px",width:"100%"}} src={loadinggif}/>
                            <img style={{height:"250px",width:"100%",paddingBottom:"50px"}} src={loadinggif}/>
                        </div> :
                        <div className="row m-0 kyc">
                            <div className="col-md-3"></div>
                            <div className="col-md-6">
                                {/*<h2 className="text-center p-3">KOCO KYC  Verification</h2>*/}
                                <div className="row">
                                    <div className="col-md-12" style={{padding:"0px 40px",textAlign:"center"}}>
                                        {
                                            val.step=="1" && val.verify!=="Approved" && val.verify!=="Inreview"?
                                                <img style={{width:"100%",marginTop:"20px",
                                                    marginBottom:"30px"}} src="/step1.png"/>:""
                                        }
                                        {
                                            val.step=="2" && val.verify!=="Approved" && val.verify!=="Inreview"?
                                                <img style={{width:"100%",marginTop:"20px",
                                                    marginBottom:"30px"}} src="/step2.png"/>:""
                                        }
                                        {
                                            val.step=="3" || val.verify=="Approved" || val.verify=="Inreview"?
                                                <img style={{width:"100%",marginTop:"20px",
                                                    marginBottom:"30px"}} src="/step3.png"/>:""
                                        }
                                    </div>
                                </div>

                                {
                                    val.step=="1" && val.verify!="Approved" && val.verify!="Inreview"?
                                        <>
                                            <div className="row m-0 selectd">
                                                <div className="col-md-6 col-6" style={{paddingRight:"30px",paddingLeft:"0"}}>
                                                    <label htmlFor="exampleFormControlInput1"
                                                           className="form-label">
                                                        Choose Country
                                                    </label>
                                                    <select onChange={this.country} className="form-select form-select-sm shadow-none mb-3"
                                                            aria-label=".form-select-sm example">
                                                        <option value="">select your country</option>
                                                        {countrylist}
                                                    </select>
                                                </div>
                                                <div className="col-md-6 col-6" style={{paddingLeft:"30px",paddingRight:"0"}}>
                                                    <label htmlFor="exampleFormControlInput1"
                                                           className="form-label">
                                                        Document Type
                                                    </label>
                                                    <select onChange={this.dtype} className="form-select form-select-sm shadow-none mb-3"
                                                            aria-label=".form-select-sm example">
                                                        <option value="">select document type</option>
                                                        <option selected={val.dtype=="National ID"} value="National ID">National ID</option>
                                                        <option selected={val.dtype=="Passport"} value="Passport">Passport</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {
                                                val.dtype!==""?<>
                                                    <label htmlFor="exampleDataList" className="form-label">{val.dtype} No</label>
                                                    <input onChange={this.nidno}
                                                           value={val.nidno} className="form-control form-control-md shadow-none"
                                                           list="datalistOptions" id="exampleDataList"
                                                           placeholder={val.dtype}/>
                                                </>:""
                                            }

                                            <div className="image">
                                                {
                                                    val.frontp!=""?
                                                        <>
                                                            <img src={this.state.frontp1}/>
                                                            <button onClick={this.removefrontp} className="btn btn-danger btn-sm shadow-none">Remove</button>
                                                        </>:
                                                        <>
                                                            <h1>Font Side</h1>
                                                            <p>
                                                                Upload the font side
                                                                <br/>
                                                                of your document
                                                            </p>
                                                            <input onChange={this.frontp} className="form-control form-control-sm shadow-none"
                                                                   accept="image/png, image/jpg" type="file" />
                                                        </>
                                                }
                                                {/**/}
                                            </div>
                                            <div className="image">
                                                {
                                                    val.backp!=""?
                                                        <>
                                                            <img src={this.state.backp1}/>
                                                            <button onClick={this.removebackp} className="btn btn-danger btn-sm shadow-none">Remove</button>
                                                        </>:
                                                        <>
                                                            <h1>Back Side</h1>
                                                            <p>
                                                                Upload the Back Side
                                                                <br/>
                                                                of your document
                                                            </p>
                                                            <input onChange={this.backp} className="form-control form-control-sm shadow-none"
                                                                   accept="image/png, image/jpg" type="file" />
                                                        </>
                                                }

                                            </div>
                                            <p className="mt-3">
                                                <input style={{border:"1px solid #0D6EFD",marginRight:"10px"}}
                                                       className="form-check-input shadow-none" onClick={this.checkbox} checked={val.checked} type="checkbox"
                                                       aria-label="Checkbox for following text input"/>
                                                <span >
                                            I confirm that I uploaded valid

                                            government-issued photo ID.
                                         </span>
                                            </p>

                                            <button onClick={this.nextc}>Continue</button>
                                        </>
                                        :""
                                }



                                {val.step=="2" && val.verify!=="Approved" && val.verify!=="Inreview"?
                                    <>
                                        <a href="https://twitter.com/KocoCoinNews" target="_blank">Follow our X account <i
                                            className="fas fa-chevron-right"></i></a>
                                        <a href="https://t.me/KocoCoinExchange" target="_blank">Join Our TG Channel <i
                                            className="fas fa-chevron-right"></i></a>
                                        <label htmlFor="exampleDataList" className="form-label">Submit X Username</label>
                                        <input onChange={this.twitter}
                                               value={val.twitter} className="form-control form-control-md shadow-none" list="datalistOptions" id="exampleDataList"
                                               placeholder="@username"/>

                                        <label htmlFor="exampleDataList" className="form-label">Submit TG Username</label>
                                        <input onChange={this.telegram}
                                               value={val.telegram} className="form-control form-control-md shadow-none" list="datalistOptions" id="exampleDataList"
                                               placeholder="@username"/>

                                        <br/>


                                        <button onClick={this.submitD} disabled={val.loading}>
                                            {val.loading==true?"Loading...":"Submit"}
                                        </button>
                                    </>:""}

                                {val.step=="3" || val.verify=="Approved" || val.verify=="Inreview"?
                                    <div className="text-center" style={{padding:"50px 0px"}}>
                                        <img src="/check.png" style={{marginBottom:"50px"}}/>
                                        {val.verify=="Inreview"?
                                            <h3>
                                                Your data is being verified.
                                                <br/>
                                                Please Wait for 24hours</h3>
                                            :""
                                        }
                                        {val.verify=="Approved"?
                                            <h3>
                                                Your data has been successfully verified. </h3>
                                            :""
                                        }
                                    </div>
                                    :""
                                }


                            </div>
                        </div>
                }

            </>
        );
    }
}

export default Kyc;