import React, { Component } from 'react'
import {Link} from "react-router-dom";
import {toast} from "react-toastify";

class Footer extends Component {
    successMsg=(val)=>{
        toast.info(val,  {
            theme: "colored",
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    comingSoon=()=>{
        this.successMsg("Coming soon...")
    }
    render() {
        return (
            <div className="footer">
               <div className="row m-0">
                   <div className="col-md-3 col-6">
                       <h4>About Us</h4>
                       <ul>
                           <li>
                               <Link to="/about">About</Link>
                           </li>
                           <li>
                               <Link to="/terms">User Agreement</Link>
                           </li>
                           <li>
                               <Link to="/terms">Privacy Policy</Link>
                           </li>
                           <li>
                               <Link target="_blank" to="/roadmap.pdf">Roadmap</Link>
                           </li>
                           <li>
                               <Link to="/news-blog">KOCO Blog</Link>
                           </li>
                       </ul>
                   </div>
                   <div className="col-md-3 col-6">
                       <h4>Services</h4>
                       <ul>
                           <li>
                               <a target="_blank" href="https://t.me/KocoCoinExchange">Help Center</a>
                           </li>
                           <li onClick={this.comingSoon}>
                               <Link to="#">Submit a Ticket</Link>
                           </li>
                           <li onClick={this.comingSoon}>
                               <Link to="#">Delistings</Link>
                           </li>
                       </ul>
                   </div>
                   <div className="col-md-3 col-6">
                       <h4>Products</h4>
                       <ul>
                           <li onClick={this.comingSoon}>
                               <Link to="#">Spot Trading</Link>
                           </li>
                           <li onClick={this.comingSoon}>
                               <Link to="#">Margin Trading</Link>
                           </li>
                           <li onClick={this.comingSoon}>
                               <Link to="#">Convert</Link>
                           </li>
                           <li onClick={this.comingSoon}>
                               <Link to="#">Futures Trading</Link>
                           </li>
                           <li onClick={this.comingSoon}>
                               <Link to="#">KocoCoin Earn</Link>
                           </li>
                       </ul>
                   </div>
                   <div className="col-md-3 col-6">
                       <h4>App Download</h4>
                       <ul>
                           <li>
                               <a target="_blank" href="/app-release.apk">Android Download</a>
                           </li>
                           <li onClick={this.comingSoon}>
                               <Link to="#">IOS Download</Link>
                           </li>
                       </ul>
                   </div>
               </div>
            </div>
        )
    }
}

export default Footer
