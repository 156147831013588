import React, {Component} from 'react';
import TopMenu from "../components/TopMenu";
import Footer from "../components/Footer";
import Axios from "axios";
import ApiUrl from "../AppUrl/ApiUrl";
import ReactHtmlParser, {htmlparser2} from "react-html-parser";
import loadinggif from"../images/loading.gif"

class Airdrop extends Component {
    constructor() {
        super();
        this.state={
            airdroptext:"",loading:true
        }
    }
    componentDidMount() {
        Axios.get(ApiUrl.baseurl+"settings/get")
            .then(res=>{
                //console.log(res.data.airdroptext)
                if(res.data.airdroptext){
                    this.setState({airdroptext:res.data.airdroptext})
                }
                this.setState({loading:false})
            })
            .catch(err=>{
                //
            })
    }

    render() {
        return (
            <>
                <TopMenu title="KocoCoin Airdrop"/>
                <div className="terms">
                    <h1>Airdrop</h1>
                    <br/>
                    <p className="reactquilldes">
                        {
                            this.state.loading==true?
                                <div style={{width:"100%",height:"100%",background:"white"}}>
                                    <img style={{height:"250px",width:"100%",paddingTop:"50px"}} src={loadinggif}/>
                                    <img style={{height:"250px",width:"100%"}} src={loadinggif}/>
                                    <img style={{height:"250px",width:"100%",paddingBottom:"50px"}} src={loadinggif}/>
                                </div> :
                                ReactHtmlParser(this.state.airdroptext)
                        }
                    </p>
                    <br/><br/><br/><br/><br/><br/>
                </div>

                <Footer/>
            </>
        );
    }
}

export default Airdrop;
