import React, {Component} from 'react';
import TopMenu from "../components/TopMenu";
import bannertop from "../images/referraltop.png"
import Footer from "../components/Footer";
import jwtDecode from "jwt-decode";
import {toast} from "react-toastify";


class Referral extends Component {
    constructor() {
        super();
        this.state={
            token:"",weburl:"",ref_code:""
        }
    }
    componentDidMount() {
        this.setState({weburl:window.location.hostname})
        var token = localStorage.getItem("authtoken")
        if(token){
            var decoded = jwtDecode(token)
            //console.log(decoded.user_id)
            this.setState({token:token,ref_code:decoded.user_id})
        }else {
            this.props.history.push("/login")
        }
    }
    successMsg=(val)=>{
        toast.info(val,  {
            theme: "colored",
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    onCopyClipboard=(val)=>{
        navigator.clipboard.writeText(val)
        this.successMsg("Copied : "+val)
    }

    render() {
        var val = this.state
        return (
            <>
                <TopMenu title="My Referral"/>
                <div className="referral">
                    <div className="topBanar">
                      <div className="row m-0">
                          <div className="col-md-7">
                              <img src={bannertop}/>
                          </div>
                          <div className="col-md-5">
                              <h1 className="text-white">
                                  Refer Friends.<br/>
                                  Get 1 koco coin<br/>
                                  Each Refer
                              </h1>
                              <p>Earn up to 10% commission on every single refer to earn koco coin</p>
                          </div>
                      </div>
                    </div>
                   <div className="referralBottom">
                      <h3>Your Referral Link</h3>
                       <div className="reflinkarea">
                         <div className="refcode">
                             <p><i className="fas fa-code"></i> Referral Code :
                                 <span style={{textAlign:"center!important"}}>  {val.ref_code}</span>
                                 <i onClick={this.onCopyClipboard.bind(this,val.ref_code)} className="fas fa-copy float-end" style={{cursor:"pointer"}}></i>
                             </p>
                         </div>
                           <div className="refcode">
                               <p><i className="fas fa-share-alt"></i> Referral Link :
                                   <span style={{textAlign:"center!important"}}>  { "https://"+this.state.weburl+"/?ref="+val.ref_code}</span>
                                   <i onClick={this.onCopyClipboard.bind(this,"https://"+this.state.weburl+"/?ref="+val.ref_code)}
                                      className="fas fa-copy float-end" style={{cursor:"pointer"}}></i>
                               </p>
                           </div>
                           <button onClick={this.onCopyClipboard.bind(this,"https://"+this.state.weburl+"/?ref="+val.ref_code)}>Invite Friends</button>
                       </div>
                   </div>


                    <br/><br/>
                </div>
                <Footer/>
            </>
        );
    }
}

export default Referral;
