import React, {Component} from 'react';
import TopMenu from "../components/TopMenu";
import Footer from "../components/Footer";
import {Link} from "react-router-dom";
import QRCode from "react-qr-code";
import {ethers} from "ethers";
import Axios from "axios";
import ApiUrl from "../AppUrl/ApiUrl";
import {toast} from "react-toastify";


class Deposit extends Component {
    constructor() {
        super();
        this.state={
            copyad:false,coin_symbol:"KOCO",wallet:[],token:"",name:"",
            coin_name:"",balance:"0",logo_img:"",fee:"0",coin_id:"",platform:"",
            fee_coin:"",deposit:"",address:"",withdrew:"",fee_symbol:"KOCO",min_deposit:""
        }
    }
    componentDidMount() {
        var token = localStorage.getItem("authtoken")
        if(token){
            this.setState({token:token})
        }else {
            this.props.history.push("/login")
        }
        const query = new URLSearchParams(this.props.location.search);
        var coin = query.get('coin')
        if(coin){
            this.setState({coin_symbol:coin})
            //console.log("coin Search ",coin)
        }
        var walletdata = JSON.parse(localStorage.getItem("wallet"))
        if(walletdata){
            this.setState({wallet:walletdata})
        }else{
            this.getWallet()
        }
        setTimeout(()=>{
            this.getCurrentCoin()
        },200)
    }
    getWallet=()=>{
        var formd = new FormData()
        formd.append("token",this.state.token)
        Axios.post(ApiUrl.baseurl+"auth/mywallet",formd)
            .then(res=>{
                //console.log(res.data.data)
                if (res.data.success){
                    this.setState({wallet:res.data.data,loading:false})
                    localStorage.setItem("wallet",JSON.stringify(res.data.data))
                }
                this.getCurrentCoin()
            })
            .catch(err=>{
                this.getWallet()
            })
    }

    onCopyClipboard=(val)=>{
        navigator.clipboard.writeText(val)
        this.setState({copyad:true})
        setTimeout(()=>{
            this.setState({copyad:false})
        },3000)
    }

    name=(e)=>{
        this.setState({name:e.target.value})
    }
    errorMsg=(val)=>{
        toast.error(val, {
            theme: "colored",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    getCurrentCoin=()=>{
        //console.log(this.state.coin_symbol)
        var coin = this.state.wallet.filter((val)=>{
            if(val.coin_symbol.toLowerCase().includes(this.state.coin_symbol.toLowerCase())){
                return val;
            }
        })
        //console.log(coin)
        if(coin.length>0){
            var coins = coin[0]
            var feecoin=coins["fee_coin"]
            this.setState({
                coin_symbol:coins["coin_symbol"],coin_name:coins["coin_name"],logo_img:coins["logo_img"],fee:coins["fee"],fee_coin:coins["fee_coin"],
                deposit:coins["deposit"],withdrew:coins["withdrew"],address:coins["address"],balance:coins["balance"],coin_id:coins["coin_id"],
                platform:coins["coin_platform"],min_deposit:coins["min_deposit"]
            })
            if(coins["deposit"]=="No"){
                this.errorMsg(coins["coin_symbol"]+" Deposit not available")
                setTimeout(()=>{
                    this.props.history.push("/wallet/spot")
                },2000)
            }
            var feecoinget = this.state.wallet.filter((val)=>{
                if(val.coin_id.includes(feecoin)){
                    return val;
                }
            })
            if(feecoinget.length>0){
                this.setState({fee_symbol:feecoinget[0]["coin_symbol"]})
            }else{
                this.setState({fee_symbol:symbol})
            }
        }
    }

    selectCoin=(name,symbol,balance,logo, fee,feecoin,deposit,withdrew,address,coin_id,platform,min_deposit)=>{
        this.setState({
            coin_symbol:symbol,coin_name:name,logo_img:logo,fee:fee,fee_coin:feecoin,platform:platform,
            deposit:deposit,withdrew:withdrew,address:address,balance:balance,coin_id:coin_id,min_deposit:min_deposit
        })
        var feecoinget = this.state.wallet.filter((val)=>{
            if(val.coin_id.includes(feecoin)){
                return val;
            }
        })
        if(deposit=="No"){
            this.errorMsg(symbol+" Deposit not available")
            setTimeout(()=>{
                this.props.history.push("/wallet/spot")
            },2000)
        }
        //console.log(feecoinget.length)
        if(feecoinget.length>0){
            this.setState({fee_symbol:feecoinget[0]["coin_symbol"]})
        }else{
            this.setState({fee_symbol:symbol})
        }


    }


    render() {
        var coinlist = this.state.wallet.filter((val)=>{
            if(this.state.name==""){
                return val;
            }else if(val.coin_name.toLowerCase().includes(this.state.name.toLowerCase()) || val.coin_symbol.toLowerCase().includes(this.state.name.toLowerCase())){
                return val;
            }
        }).map(res=>{
            //console.log(res)
            return(
                <>
                    <div onClick={this.selectCoin.bind(this,res.coin_name,res.coin_symbol,res.balance,res.logo_img,res.fee,res.fee_coin,res.deposit,res.withdrew,res.address,res.coin_id,res.coin_platform,res.min_deposit)}
                         className="walletmobile ">
                        <div data-dismiss="modal" aria-label="Close" className="row">
                            <div className="col-md-1 col-2">
                                <img style={{height:"30px",width:"30px",marginTop:"10px"}}
                                     src={ApiUrl.photoUrl+res.logo_img}/>
                            </div>
                            <div className="col-md-7 col-6">
                                <p>
                                    {res.coin_symbol}<br/>
                                    {res.coin_name}
                                </p>
                            </div>
                            <div className="col-md-4 col-4">
                                <p className="float-end">
                                    <span className="float-end">{parseFloat(res.balance).toFixed(5)}</span>
                                    <br/>
                                    ≈ {(parseFloat(res.balance)*parseFloat(res.price)).toFixed(2)} USD
                                </p>
                            </div>
                        </div>
                    </div>

                </>
            )
        })

        return (
            <>
                <TopMenu title={"Deposit "}/>
                 <div className="deposit">
                     <h5>
                         <i style={{background:"#0085FF",color:"white",padding:"10px 12px",borderRadius:"50%",marginRight:"8px",fontSize:"12px"}}
                            className="fas fa-question"></i>
                         How To Do
                     </h5>
                     <p>
                         This address only supports deposit of {this.state.coin_symbol} assets. Do not deposit other assets to this address as the assets will not be credited or recoverable.<br/>

                         Please note: If the single deposit amount is less than the minimum deposit amount, it will not be credited. The platform will not be liable for any<br/>
                         loss of assets resulting from this. Thank you for your understanding and support!
                     </p>
                     <br/>
                     <h3>
                         <Link to="/wallet/spot">
                             <i style={{background:"#0085FF",color:"white",padding:"10px 12px",borderRadius:"50%",marginRight:"8px",fontSize:"18px"}}
                                className="fas fa-arrow-left"></i>
                         </Link>
                         Deposit
                     </h3>
                     <div className="address">
                         <div className="row">
                             <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog"
                                  aria-labelledby="exampleModalLabel" aria-hidden="true">

                                 <div className="modal-dialog" role="document">
                                     <div className="modal-content">
                                         <div className="modal-header">
                                             <h5 className="modal-title" id="exampleModalLabel">Select Crypto to Deposit</h5>
                                             <button type="button"
                                                     style={{background:"white",border:"none",fontSize:"20px"}}
                                                     className="close" data-dismiss="modal" aria-label="Close">
                                                 <i className="far fa-times"></i>
                                             </button>
                                         </div>
                                         <div className="modal-body">
                                             <div className="search">
                                                 <i className="far fa-search" style={{marginRight:"6px"}}></i>
                                                 <input onChange={this.name} value={this.state.name} placeholder="Search"/>
                                             </div>
                                             <br/><br/>
                                             {coinlist}
                                         </div>
                                     </div>
                                 </div>
                             </div>

                             <div className="col-md-6">
                                 <h5 style={{fontSize:"18px",marginBottom:"25px",marginTop:"30px"}}>Select Crypto</h5>
                                 <p className="selectcoin" data-toggle="modal" data-target="#exampleModal">
                                     <img src={ApiUrl.photoUrl+this.state.logo_img}/>
                                     {this.state.coin_symbol} <i className="fas fa-sort-down float-end"></i>
                                 </p>
                                 <h5 style={{fontSize:"18px",marginBottom:"25px",marginTop:"30px"}}>Network</h5>
                                 <p className="selectcoin" style={{cursor:"default"}}>
                                     {
                                         this.state.platform=="" || this.state.platform==undefined
                                             ?"Select deposit network":this.state.platform=="Binance"?"Binance Smart Chain"+" Network":this.state.platform+" Network"
                                     } <i className="fas fa-sort-down float-end"></i>
                                 </p>
                                 <div className="card" style={{width:"100%"}}>
                                     <div className="modal-header" style={{borderBottom:"none"}}>
                                         <h5 className="modal-title" id="exampleModalLabel">Deposit {this.state.coin_symbol}</h5>
                                     </div>
                                     <div className="card-body text-center">
                                         <QRCode
                                             size={256}
                                             style={{ height: "auto", maxWidth: "200px", width: "150px",textAlign:'center' }}
                                             value={this.state.address}
                                             viewBox={`0 0 256 256`}
                                         />
                                         <p className="w-100 mt-3 p-0" style={{lineHeight:"10px",textAlign:"left"}} > {this.state.coin_name} address
                                             <span style={{float:"right"}}>
                                                <a onClick={this.onCopyClipboard.bind(this,this.state.address)} style={{cursor:"pointer"}}>
                                                  {
                                                      this.state.copyad==false?<i className="fal fa-clone"></i>:
                                                          <i className="fas fa-check text-success"></i>
                                                  }
                                                </a>
                                          </span>
                                         </p>
                                         <p style={{float:"left",lineHeight:"15px"}} className="overflow-auto depositaddress pb-1 p-0">
                                             {this.state.address}
                                         </p>
                                         <p className="alert alert-secondary mt-5" role="alert" style={{fontSize:"12px",textAlign:"left"}}>
                                             This is a {this.state.coin_symbol} network address.
                                             Do not send {this.state.coin_symbol} over any other network to this address
                                             or your funds may be lost.<br/> Minimum Deposit {this.state.min_deposit} { this.state.coin_symbol } <br/>
                                             After send {this.state.coin_symbol} wait in wallet page 1-2 minute , if your fund not added then refresh wallet page
                                         </p>
                                     </div>
                                 </div>
                                 <br/><br/>

                             </div>
                             <div className="col-md-6 bgimgdeposit">
                                <img src="/depositi.png"/>
                             </div>
                         </div>
                     </div>
                 </div>
                <Footer/>
            </>
        );
    }
}

export default Deposit;